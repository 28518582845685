<template>
  <div v-if="reqForm" class="container">
    <h3>GENERATE REQUEST:</h3>
    <div class="formSetting">
      <form action="#" @submit="submitRequest">
        <div class="row">
          <div class="col col-lg-4 col-md-12 col-sm-12"></div>
          <div class="col col-lg-2 col-md-12 col-sm-12">
            <p>CHEVRON PO #:</p>
          </div>
          <div class="col col-lg-6 col-md-12 col-sm-12">
            <input type="text" maxlength="11" v-model="chevronPo" required />
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-4 col-md-12 col-sm-12"></div>
          <div class="col col-lg-2 col-md-12 col-sm-12">
            <p>LABEL QTY:</p>
          </div>
          <div class="col col-lg-6 col-md-12 col-sm-12">
            <input type="number" v-model="labelQty" required />
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-4 col-md-12 col-sm-12"></div>
          <div class="col col-lg-2 col-md-12 col-sm-12">
            <p>PRICE OF LABEL:</p>
          </div>
          <div class="col col-lg-6 col-md-12 col-sm-12">
            <input type="number" step="any" v-model="labelPrice" required />
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-4 col-md-12 col-sm-12"></div>
          <div class="col col-lg-2 col-md-12 col-sm-12">
            <p>DELIVER BY:</p>
          </div>
          <div class="col col-lg-6 col-md-12 col-sm-12">
            <input type="date" :min="todayDate" v-model="deliverBy" required />
          </div>
        </div>

        <div class="row">
          <div class="col col-lg-4 col-md-12 col-sm-12"></div>
          <div class="col col-lg-2 col-md-12 col-sm-12">
            <p>NOTES:</p>
          </div>
          <div class="col col-lg-6 col-md-12 col-sm-12">
            <textarea name="note" cols="40" rows="7" v-model="note"></textarea>
          </div>
        </div>

        <button type="submit" class="btn btn-primary alignCenterBtn">
          SUBMIT
        </button>
      </form>
    </div>
  </div>
  <div v-else>
    <ConfirmReq
      :chevronPo="chevronPo"
      :labelQty="Number(labelQty)"
      :labelPrice="Number(labelPrice)"
      :deliverBy="deliverBy"
      :note="note"
    />
  </div>
</template>

<script>
import "../style/style.css";
import ConfirmReq from "./ConfirmReq.vue";

export default {
  name: "GenerateReq",

  components: {
    ConfirmReq,
  },

  data() {
    return {
      chevronPo: "",
      labelQty: "",
      labelPrice: "",
      deliverBy: "",
      note: "",
      reqForm: true,
      todayDate: "",
      errorMsg: "",
    };
  },

  mounted() {
    var today = new Date();
    today.setMonth(today.getMonth());
    this.todayDate = today.toISOString().split("T")[0];
  },

  methods: {
    submitRequest(e) {
      e.preventDefault();
      this.reqForm = false;
      return false;
    },
    cancel() {
      this.reqForm = true;
    }
  },
};
</script>

<style scoped>
.row {
  margin: 10px auto;
}
</style>
