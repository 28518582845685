<template>
  <div class="container">
    <h3>REQUEST CONFIRMATION:</h3>
      <!-- Error -->
      <div
        v-show="errorFlag"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        {{ errorMsg }}
      </div>

    <div class="formSetting">
      <div class="row">
        <div class="col col-lg-4 col-md-12 col-sm-12"></div>
        <div class="col col-lg-2 col-md-12 col-sm-12">
          <p>CHEVRON PO #:</p>
        </div>
        <div class="col col-lg-6 col-md-12 col-sm-12">
          <p class="bold">{{ chevronPo }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-4 col-md-12 col-sm-12"></div>
        <div class="col col-lg-2 col-md-12 col-sm-12">
          <p>LABEL QTY:</p>
        </div>
        <div class="col col-lg-6 col-md-12 col-sm-12">
          <p class="bold">{{ labelQty }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-4 col-md-12 col-sm-12"></div>
        <div class="col col-lg-2 col-md-12 col-sm-12">
          <p>PRICE OF LABEL:</p>
        </div>
        <div class="col col-lg-6 col-md-12 col-sm-12">
          <p class="bold">{{ labelPrice }} Rupees</p>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-4 col-md-12 col-sm-12"></div>
        <div class="col col-lg-2 col-md-12 col-sm-12">
          <p>DELIVER BY:</p>
        </div>
        <div class="col col-lg-6 col-md-12 col-sm-12">
          <p class="bold">{{ deliverBy }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-4 col-md-12 col-sm-12"></div>
        <div class="col col-lg-2 col-md-12 col-sm-12">
          <p>NOTES:</p>
        </div>
        <div class="col col-lg-6 col-md-12 col-sm-12">
          {{ note }}
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 text-center">
          <button class="btn btn-primary btn-md" v-on:click="confirmation" :disabled="submitted">
            CONFIRM
          </button>
          &nbsp;
          <button class="btn btn-secondary btn-md" v-on:click="cancel">
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronApi from "../lib/chevron-api"
import "../style/style.css"

export default {
  name: "ConfirmReq",
  props: {
    chevronPo: String,
    labelQty: Number,
    labelPrice: Number,
    deliverBy: String,
    note: String,
  },

  data() {
    return {
      errorMsg : "",
      errorFlag: false,
      submitted: false,
    };
  },

  methods: {
    confirmation() {
      this.submitted = true;
      this.errorFlag = false;

      const api = new ChevronApi()
      
      api.createPo(this.chevronPo, this.labelQty, this.labelPrice, this.deliverBy, this.note)
        .then(() => {
          this.$router.replace("/home/dashboard")
          this.submitted = false;
        })
        .catch((error) => {
          this.errorFlag = true;
          this.submitted = false;
          
          try {
            this.errorMsg = error.response.data.message;
          } 
          catch (_) {
            // that means undefined error.response.data.message
            this.errorMsg = error;
          }
        });
    },

    cancel() {
      if (confirm('Are you sure you would like to cancel?')) {
        this.$parent.cancel();
      }
    }
  },
};
</script>

<style scoped>
.row {
  margin: 10px auto;
}
button {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
