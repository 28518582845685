<template>
<div class="container">
    <h3>INVENTORY DETAIL'S:</h3>
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12">
            <div class="inventoryContainer">
                <div class="row">
                    <div class="col col-lg-12 col-md-12 col-sm-12">
                        <h4>SICPA INVENTORY DETAIL’S:</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-lg-6 col-md-6 col-sm-12">
                        <div class="row border-col">
                            <div class="col col-lg-8 col-md-8 col-sm-12">
                                <h6>TOTAL GENERATED CODE'S</h6>
                            </div>
                            <div class="col col-lg-4 col-md-4 col-sm-12 alignRight">
                                <h6>{{ this.generated }}</h6>
                            </div>
                        </div>
                        <div class="row border-col">
                            <div class="col col-lg-8 col-md-8 col-sm-12">
                                <h6>TOTAL PRINTED CODE'S</h6>
                            </div>
                            <div class="col col-lg-4 col-md-4 col-sm-12 alignRight">
                                <h6> {{ this.printed }} </h6>
                            </div>
                        </div>
                        <div class="row border-col">
                            <div class="col col-lg-8 col-md-8 col-sm-12">
                                <h6>REJECTED CODE'S</h6>
                            </div>
                            <div class="col col-lg-4 col-md-4 col-sm-12 alignRight">
                                <h6> {{ this.rejected }} </h6>
                            </div>
                        </div>
                    </div>

                    <div class="col col-lg-6 col-md-6 col-sm-12">
                        <div class="row border-col">
                            <div class="col col-lg-12 col-md-12 col-sm-12">
                                <h6>PRINTED SHIPMENT’S</h6>
                                <h2 class="alignRight"> {{ this.printed }} </h2>
                            </div>
                        </div>
                        <div class="row border-col">
                            <div class="col col-lg-12 col-md-12 col-sm-12">
                                <h6>IN-PROGRESS SHIPMENT</h6>
                                <h2 class="alignRight">{{ this.inprogress }}</h2>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    </div>
</div>  
</template>

<script>
import ChevronApi from "../lib/chevron-api"
import "../style/style.css"

export default {
  name: "InventoryDetails",
  
  data() {
    return {
      generated: 0,
      printed: 0,
      rejected: 0,
      shipped: 0,
      inprogress: 0,
      errorFlag: false,
      errorMsg: "",
    }
  },
  
  mounted() {
    const api = new ChevronApi()
    api.getInventorySummary()
        .then((res) => {
            this.generated = res.generated;
            this.printed = res.printed;
            this.rejected = res.rejected;
            this.shipped = res.shipped;
            this.inprogress = res.inprogress;
        })
        .catch((error) => {
            this.errorFlag = true;
            this.errorMsg = error;
        });
  }
}  
</script>

<style scoped>
.inventoryContainer{
  width: 100%;
  padding: 30px;
  border: 1px solid black;
  margin-bottom: 50px;
}
.border-col{
    padding: 20px;
}
</style>