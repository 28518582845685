import axios from "axios";
import store from "../store.js";

class ApiException extends Error {
  constructor(code, message) {
    super();
    this.code = code;
    this.message = message;
  }
}

function getResponseData(response) {
  if (response.data !== undefined) {
    // the old style API had a data member inside its response
    // with a message and status field in body to indicate success
    // newer style API used http status code not the body for this
    if (response.data.data !== undefined && 
      response.data.message !== undefined && 
      response.data.transactionResult !== undefined) {
        // it definitely is an old style API
        return response.data.data;
      }
    else {
      return response.data;
    }
  }

  return response;
}

function throwError(error) {
  if (error.response) {
    if (error.response.data !== undefined)
      throw new ApiException(error.response.status, error.response.data.message);
    else
      throw new ApiException(error.response.status, `Error occurred on the server. Error code: ${error.response.staus}`);
  } else if (error.request) {
    throw new ApiException(404, "Problem with request! 404 Not found");
  } else {
    throw new ApiException(0, "The server could not be reached");
  }
}

class ChevronApi {
  constructor() {
    var host;
    if (window.location.host.startsWith('localhost') || window.location.host.startsWith('127.0.0.1'))
      host = "http://localhost:8011";
    else
      host = "https://apichevron.atksrv.net";

    this.base = `${host}/api`
    this.healthUrl = `${host}/health`
  }

  getBase() {
    return this.base;
  }

  post(url, body) {
    return axios.post(`${this.base}${url}`, body, {
        headers: {
          "x-auth-token": store.state.token,
        },
      })
      .then(getResponseData)
      .catch(throwError);
  }

  get(url) {
    const token = store.state.token;
    const headers = {
      "x-auth-token": token,
    };

    return axios.get(`${this.base}${url}`, 
      {
        headers
      })
      .then(getResponseData)
      .catch(throwError);
  }

  login(email, password) {
    return this.post('/token/get', {
        emailAddress: email,
        password: password,
        code: "chevron",
    })
  }

  serverVersion() {
    return axios.get(`${this.healthUrl}/version`) 
      .then(getResponseData)
      .catch(throwError);
  }

  createPo(chevronPo, labelQty, labelPrice, deliverBy, note) {
    return this.post(
        `/secure/chevron/`,
        {
          chevronPONo: chevronPo,
          quantity: labelQty,
          priceOfLabel: labelPrice,
          deliveryDate: deliverBy,
          notes: note,
        },
      );
  }

  getSummary() {
    return this.get("/secure/chevron/purchaseOrders/summary");
  }

  getPagedRequestList(currentPage, setStartDate, setEndDate, setStatus) {
    let url;
    
    if (setStartDate === undefined && setEndDate === undefined && setStatus === undefined) {
      url = `/secure/chevron/purchaseOrders?pageNo=${currentPage}&recordsPerPage=7`;
    }

    else if (setStartDate !== undefined && setEndDate !== undefined && setStatus === undefined) {
      url = `/secure/chevron/purchaseOrders?startDate=${setStartDate}&endDate=${setEndDate}&pageNo=${currentPage}&recordsPerPage=7`;
    }

    else if (setStartDate !== undefined && setEndDate !== undefined && setStatus !== undefined) {
      url = `/secure/chevron/purchaseOrders?status=${setStatus}&startDate=${setStartDate}&endDate=${setEndDate}&pageNo=${currentPage}&recordsPerPage=7`;
    }
  
    return this.get(url);
  }

  getRequestDetailFromID(id) {
    return this.get(`/secure/chevron/${id}`)
  }

  getInventorySummary() {
    return this.get(`/secure/chevron/inventory`)
  }

  markDelivered(id) {
    return this.post(`/secure/chevron/delivered?poId=${id}`)
  }

  markCancelled(id) {
    return this.post(`/secure/chevron/cancelled?poId=${id}`)
  }

  markReady(id) {
    return this.post(`/secure/chevron/ready?poId=${id}`)
  }

  downloadCodes(poRequestId) {
    const token = store.state.token;
    
    return axios({
      url: this.base + "/secure/chevron/codesCsv?poId=" + poRequestId,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        "x-auth-token": token,
      },
    });
  }

  downloadSicpaFile(poRequestId, onProgress) {
    const token = store.state.token;
    
    return axios({
      url: this.base + "/secure/chevron/sicpaFile?poId=" + poRequestId,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        "x-auth-token": token,
      },
      onDownloadProgress: onProgress
    });
  }

  downloadPackage(poRequestId) {
    const token = store.state.token;
    
    return axios({
      url: this.base + "/secure/chevron/package?poId=" + poRequestId,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        "x-auth-token": token,
      },
    });
  }
}

export default ChevronApi;
