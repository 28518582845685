<template>
  <div class="container-fluid header">
    <div class="row">
      <div class="col col-lg-6 col-md-6 col-sm-6">
        <div class="logo">
          <img src="../image/logo.png" alt="Logo" />
        </div>
      </div>
    </div>
  </div>

  <div class="container loginMainDiv">
    <form>
      <div class="row">
        <div class="col col-lg-5 col-md-5 col-sm-12 alignCenter">
          <h6>Username:</h6>
        </div>
        <div class="col col-lg-7 col-md-7 col-sm-12">
          <input
            class="username"
            type="text"
            placeholder="Username"
            v-model="username"
            required
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col col-lg-5 col-md-5 col-sm-12 alignCenter">
          <h6>Password:</h6>
        </div>
        <div class="col col-lg-7 col-md-7 col-sm-12">
          <input
            class="username"
            type="password"
            name="password"
            autocomplete="off"
            placeholder="Password"
            v-model="password"
            required
          />
          <p class="error" v-show="error">{{ errorMsg }}</p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col col-lg-5 col-md-5 col-sm-12"></div>
        <div class="col col-lg-7 col-md-7 col-sm-12">
          <button
            class="btn btn-primary"
            type="button"
            v-on:click="loginApiCall"
          >
            LOGIN
          </button>
          <br />
        </div>
      </div>
    </form>

    <div style="font-size:10pt; margin-right: 5pt; margin-bottom: 1pt; position: absolute; bottom: 0; right:0">
      Server Version: {{ version }}
    </div>

  </div>
</template>

<script>
import ChevronApi from "../lib/chevron-api";
import "../style/style.css";
import store from "../store.js";

export default {
  name: "Login",

  data() {
    return {
      username: "admin@authentiksolution.com",
      password: "a12345678",
      error: false,
      errorMsg: "",
      version: "",
    };
  },

  mounted() {
    const api = new ChevronApi()
    api.serverVersion()
      .then((response) => {
        this.version = `${response.server}/${response.numberService}`
      })
      .catch((error) => {
        this.errorFlag = true;
        this.errorMsg = error;
      })
  },

  methods: {
    loginApiCall() {
      const api = new ChevronApi();
      api
        .login(this.username, this.password)
        .then((response) => {
          store.commit("setToken", response.token);
          this.redirect();
        })
        .catch((error) => {
          this.errorMsg = error.message;
          this.error = true;
        });
    },

    redirect() {
      if (store.state.token !== "") {
        this.$router.push("/home/dashboard");
      }
    },
  },
};
</script>

<style scoped>
.loginMainDiv {
  border: 1px solid black;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 40%;
  margin-top: 100px;
  position: relative;
}
.username {
  width: 85%;
}
@media (max-width: 992px) {
  .loginMainDiv {
    width: 50%;
  }
}

@media (max-width: 768px) {
  input,
  button {
    display: block;
    margin: auto;
  }
  .loginMainDiv {
    margin-bottom: 200px;
  }
}
</style>
