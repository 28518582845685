<template>
  <div class="container">
    <div class="row">
      <!-- main dashboard -->
      <div class="col col-lg-12 col-md-12 col-sm-12">
        <div class="row">
          <div class="col col-lg-3 col-md-3 col-sm-6">
            <div class="requestDetails bg-warning text-dark">
              <h4>TOTAL REQUEST</h4>
              <p>{{ totalRequests }}</p>
            </div>
          </div>
          <div class="col col-lg-3 col-md-3 col-sm-6">
            <div class="requestDetails bg-danger">
              <h4>PENDING REQUEST</h4>
              <p>{{ pendingRequests }}</p>
            </div>
          </div>
          <div class="col col-lg-2 col-md-3 col-sm-6">
            <div class="requestDetails bg-info">
              <h4>REQUEST IN PROGRESS</h4>
              <p>{{ inProgressRequests }}</p>
            </div>
          </div>
          <div class="col col-lg-2 col-md-3 col-sm-6">
            <div class="requestDetails bg-success">
              <h4>REQUEST COMPLETED</h4>
              <p>{{ completeRequests }}</p>
            </div>
          </div>
          <div class="col col-lg-2 col-md-3 col-sm-6">
            <div class="requestDetails bg-success">
              <h4>REJECTED REQUESTS</h4>
              <p>{{ rejectedRequests }}</p>
            </div>
          </div>
        </div>

        <!-- Error -->
        <div
          v-show="errorFlag"
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {{ errorMsg }}
        </div>

        <div class="row">
          <div class="col col-lg-12 col-md-12 col-sm-12">
            <div class="containerRecentReq">
              <h5>RECENT REQUEST</h5>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Chevron PO</th>
                      <th>Added Date</th>
                      <th>Quantity</th>
                      <th>Deliver Date</th>
                      <th>Status</th>
                      <th>Total Printed / Generated</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="r in poRequests" v-bind:key="r.id">
                      <td>{{ r.serialNo }}</td>
                      <td class="bold alignLeft">{{ r.chevronPONo }}</td>
                      <td>{{ new Date(r.dateAdded).toDateString() }}</td>
                      <td>{{ r.quantity }}</td>
                      <td>{{ new Date(r.deliveryDate).toDateString() }}</td>
                      <td>{{ r.status }}</td>
                      <td>{{ r.totalPrinted }} / {{ r.totalGenerated }} </td>
                      <td><button class="btn bg-dark-grey" v-on:click="requestDetail(r.id)">Details</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <ul class="pagination just-center" v-show="lastPageNoToShow > 1">
              <li class="page-item" :class="index === currentPage ? 'active' : ''" v-for="index in lastPageNoToShow" :key="index" >
                <a v-on:click="getPagedRequest(index)" class="page-link">{{ index }}</a>
              </li>
            </ul>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col col-lg-12 col-md-12 col-sm-12">
            <div class="inventorySection">
              <h5>INVENTORY MANAGEMENT</h5>
              <div class="row containerChart">
                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <div class="chart">
                    <img src="../image/chevron-chart.png" alt="Chevron Chart" />
                  </div>
                </div>

                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <div class="chart">
                    <img src="../image/sicpa-chart.png" alt="Chevron Chart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
</template>

<script>
import "../style/style.css";
import ChevronApi from "../lib/chevron-api";
import Util from "../lib/util";

export default {
  name: "Dashboard",

  data() {
    return {
      totalRequests: 0,
      pendingRequests: 0,
      inProgressRequests: 0,
      completeRequests: 0,
      rejectedRequests: 0,
      poRequests: [],
      errorMsg: "",
      errorFlag: false,
      currentPage: 1,
      recordPerPage: 7,
      startPageNoToShow: 1,
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      timerId : 0,
    };
  },

  mounted() {
    this.getPagedRequest(this.currentPage, this.startDate, this.endDate, this.status);

    new ChevronApi().getSummary().then(summary => {
      // this.total = is set when the data comes in, so we do not set it here
      this.pendingRequests = summary.pending;
      this.inProgressRequests = summary.progress;
      this.completeRequests = summary.delivered;
      this.rejectedRequests = summary.rejected;
    });
  },

  computed: {
    lastPageNoToShow() {
      var count = 10;
      if (this.totalRequests === 0) {
        return 1;
      }
      var last = this.startPageNoToShow + 10;
      var lastCount = last * this.recordPerPage;
      if (lastCount > this.totalRequests) {
        count = Math.ceil(this.totalRequests / this.recordPerPage) - this.startPageNoToShow + 1;
      }
      return count;
    },
  },

  methods: {
    getPagedRequest(index) {
      const api = new ChevronApi();

      api.getPagedRequestList(index - 1, this.startDate, this.endDate, this.status)
        .then((response) => {
          this.errorFlag = false;
          this.poRequests = response.items;
          this.totalRequests = response.totalRecords;
          this.currentPage = index;
          
          var i = (this.currentPage - 1) * 7 + 1;
          var anyPending = false;

          for (var r of this.poRequests) {
            r.serialNo = i++;
            r.quantity = Util.numberWithCommas(r.quantity)
            r.totalGenerated = Util.numberWithCommas(r.totalGenerated)
            r.totalPrinted = Util.numberWithCommas(r.totalPrinted)

            if (r.status == 'Pending') {
              // we need to have a timer that refreshes every now often
              anyPending = true;
            }
          }

          if (anyPending) {
            setTimeout(() => {
              this.getPagedRequest(index)
            }, 1000);
          }
        })
        .catch((error) => {
          this.errorMsg = error;
          this.errorFlag = true;
        });
    },
 
    requestDetail(id) {
      this.$router.push(`/home/request-detail/${id}`)
    },
  },
};
</script>

<style scoped>
.requestDetails {
  height: 85px;
  padding: 15px 0;
  border: 2px solid darkgray;
  color: snow;
  text-align: center;
}
.requestDetails h4 {
  font-size: 11pt;
}
.containerRecentReq {
  margin-top: 50px;
  border: 2px solid black;
  text-align: left;
  padding: 30px;
}
.inventorySection {
  margin: 50px auto;
  text-align: left;
}
.containerChart {
  border: 2px solid black;
  height: 350px;
  padding-top: 15px;
}
.chart {
  border: 2px solid darkgray;
  height: 320px;
  text-align: center;
}
.chart img {
  width: 100%;
  height: 100%;
}
.th {
  background-color: gray;
}

@media (max-width: 767px) {
  .containerChart {
    border: 2px solid black;
    height: 600px;
    padding-top: 15px;
  }
}
</style>
