import { createWebHistory, createRouter } from 'vue-router';
import Login from './components/Login.vue';
import Home from './components/Home.vue';
import Dashboard from './components/Dashboard.vue';
import GenerateReq from './components/GenerateReq.vue';
import RequestList from './components/RequestList.vue';
import RequestDetails from './components/RequestDetails.vue';
import InventoryDetails from './components/InventoryDetails.vue';

const routes = [
    {
        name: 'Login',
        path: '/',
        component: Login
    },
    {
        name: 'Home',
        path: '/home',
        component: Home,
        children: [
            {
                path: '/home/dashboard',
                component: Dashboard
            },
            {
                path: '/home/generate-request',
                component: GenerateReq
            },
            {
                path: '/home/request-list',
                component: RequestList,
            },
            {
                path: '/home/request-detail/:id',
                component: RequestDetails,
            },
            {
                path: '/home/inventory-detail',
                component: InventoryDetails,
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;